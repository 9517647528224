import type { EndUserPages } from '@aurora/shared-types/pages/enums';
import type { I18n } from '@aurora/shared-types/texts';
import { isLeftEqual } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import type { BaseRouteAndParams } from '@aurora/shared-utils/helpers/urls/NextRoutes/Route';
import React, { useEffect, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import type { UseFormReturn } from 'react-hook-form/dist/types';
import { SharedComponent } from '../../../enums';
import type { AdminPages } from '../../../routes/adminRoutes';
import useRoutesForCurrentApp from '../../../routes/useRoutesForCurrentApp';
import { ButtonVariant, LoadingButtonVariant } from '../../common/Button/enums';
import ConfirmationDialog from '../../common/ConfirmationDialog/ConfirmationDialog';
import useTranslation from '../../useTranslation';
import { useFormWatch } from '../useFormWatch';

export interface UnsavedChangedDialogProps<FormDataT extends FieldValues> {
  /**
   * formState state of the form
   */
  formMethods: UseFormReturn<FormDataT>;

  /**
   * defaultValues default values for the form
   */
  defaultValues: FormDataT;

  /**
   * id of the form
   */
  id: string;

  /**
   * i18n of the component that is rendering the form
   */
  i18n: I18n<unknown, unknown>;
}

/**
 *
 * @author Manish Shrestha
 */
const UnsavedChangedDialog = <FormDataT extends FieldValues>({
  formMethods: { formState, reset, control },
  defaultValues,
  id,
  i18n
}: UnsavedChangedDialogProps<FormDataT>): React.ReactElement => {
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [routeChangeUrl, setRouteChangeUrl] = useState<string | null>(null);
  const { formatMessage, hasMessage, loading: textLoading } = i18n;
  const { formatMessage: localFormatMessage, loading: localTextLoading } = useTranslation(
    SharedComponent.UNSAVED_CHANGED_DIALOG
  );
  const { dirtyFields } = formState;
  const { router, loading: routesLoading } = useRoutesForCurrentApp();
  const currentValues = useFormWatch('all', control);
  /**
   * This change is added to provide support to InputEditForm to display an alert for unsaved changes when a user
   * tries to navigate to another route within the application intentionally/unintentionally. As of today, there is no support
   * provided by next/router to show an alert before a route change is initiated or cancel a route conditionally. In future,
   * if NextJS offers a way to do this in a better way, we can refactor this code accordingly, but for now a workaround
   * has been implemented as suggested here - https://github.com/vercel/next.js/issues/2476#issuecomment-563190607
   * This change is added here because so that it can be used in any page that makes use of the InputEditForm and can
   * be controlled by a single prop warnForUnsavedChanges
   *
   * Also, another important thing to consider would be that React Hook Form compares the data type received from defaultValues
   * to the output of the getValues function. The formState can be unexpectedly dirty in two scenarios -
   * 1. If the default value of any text field is undefined or null
   * 2. The number of values in the object returned by defaultValue and getValues is different.
   * To avoid scenario 1, it is better to have the defaultValue for a text field as ''(empty string) rather than null/undefined. It
   * would be better to define defaultValue for input fields as defaultValue: someValue ?? '';
   * Source - https://github.com/react-hook-form/react-hook-form/issues/3213#issuecomment-753895802
   */

  useEffect(() => {
    const routeChangeStart = url => {
      const isDirty = !isLeftEqual(currentValues, defaultValues);
      if (router.asPath !== url && isDirty) {
        setRouteChangeUrl(url);
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
        setShowConfirmDialog(true);
        router.abort();
      }
    };
    router.events.on('routeChangeStart', routeChangeStart);

    return () => {
      router.events.off('routeChangeStart', routeChangeStart);
    };
  }, [currentValues, defaultValues, dirtyFields, formState, router]);

  if (textLoading || localTextLoading || routesLoading) {
    return null;
  }

  const titleTextKey = 'unsavedChangesWarningDialog.title';
  const bodyTextKey = 'unsavedChangesWarningDialog.body';
  const submitButtonTextKey = 'unsavedChangesWarningDialog.submit';
  const cancelButtonTextKey = 'unsavedChangesWarningDialog.cancel';

  const titleText = hasMessage(`${id}.${titleTextKey}`)
    ? formatMessage(`${id}.${titleTextKey}`)
    : localFormatMessage(titleTextKey);

  const bodyText = hasMessage(`${id}.${bodyTextKey}`)
    ? formatMessage(`${id}.${bodyTextKey}`)
    : localFormatMessage(bodyTextKey);

  const submitButtonText = hasMessage(`${id}.${submitButtonTextKey}`)
    ? formatMessage(`${id}.${submitButtonTextKey}`)
    : localFormatMessage(submitButtonTextKey);

  const cancelButtonText = hasMessage(`${id}.${cancelButtonTextKey}`)
    ? formatMessage(`${id}.${cancelButtonTextKey}`)
    : localFormatMessage(cancelButtonTextKey);

  /**
   * Renders modal dialog to warn user of unsaved changes
   */
  return (
    <ConfirmationDialog
      show={showConfirmDialog}
      onHide={() => setShowConfirmDialog(false)}
      onSubmit={async () => {
        reset(defaultValues);
        if (routeChangeUrl) {
          const { route, params, query } = router.getRouteAndParamsByPath(routeChangeUrl);
          await router.pushRoute<BaseRouteAndParams<EndUserPages | AdminPages>>(
            route as EndUserPages | AdminPages,
            params,
            query
          );
        }
      }}
      onCancel={() => {
        setShowConfirmDialog(false);
      }}
      titleText={titleText}
      bodyText={formatMessage(bodyText)}
      submitButtonVariant={ButtonVariant.DANGER}
      submitButtonType={LoadingButtonVariant.LOADING_BUTTON}
      submitButtonText={submitButtonText}
      cancelButtonText={cancelButtonText}
    />
  );
};

export default UnsavedChangedDialog;
