import { getLog } from '@aurora/shared-utils/log';
import type { ReactNode, Ref } from 'react';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Col, Form, Row, useClassNameMapper } from 'react-bootstrap';
import type { FieldErrors, FieldValues, UseFormSetError } from 'react-hook-form';
import type { UseFormReturn } from 'react-hook-form/dist/types';
import type { FieldPath, Path } from 'react-hook-form/dist/types/path';
import { SharedComponent } from '../../../enums';
import { FormFeedbackPosition } from '../../../helpers/form/FormFieldFeedback/enums';
import type FormFeedbackTypes from '../../../helpers/form/FormFieldFeedback/form-feedback-types';
import {
  isFormFieldType,
  isPossibleValuesField,
  transformValue
} from '../../../helpers/form/FormHelper/FormHelper';
import { registeredFields } from '../../../helpers/forms/FormFieldRegistrationHelper';
import ReCaptchaPrivacyTos from '../../authentication/ReCaptchaPrivacyTos/ReCaptchaPrivacyTos';
import { ToastAlertVariant, ToastVariant } from '../../common/ToastAlert/enums';
import useToasts from '../../context/ToastContext/useToasts';
import useTranslation from '../../useTranslation';
import type { FormFieldVariant } from '../enums';
import { FormActionButtonBarPosition, FormGroupFieldSeparator, FormGroupFieldType } from '../enums';
import FormActionButtons from '../FormActionButtons/FormActionButtons';
import FormFeedbacks from '../FormFeedbacks/FormFeedbacks';
import FormFieldset from '../FormFieldSet/FormFieldset';
import FormGroupVisibilityHandler from '../FormGroupVisibilityHandler/FormGroupVisibilityHandler';
import PossibleValuesFieldDecorator from '../PossibleValuesFieldDecorator/PossibleValuesFieldDecorator';
import type {
  DebounceProps,
  FormFieldProps,
  FormFieldSpecDefinition,
  FormFieldType,
  FormSpec,
  PossibleValuesField
} from '../types';
import UnsavedChangedDialog from '../UnsavedChangedDialog/UnsavedChangedDialog';
import useReCaptcha from '../useReCaptcha';
import localStyles from './InputEditForm.module.pcss';
import type { UseInputEditFormReturn } from './useInputEditForm';
import { useInputEditForm } from './useInputEditForm';
import TenantContext from '../../context/TenantContext';

const log = getLog(module);

export interface ActionContextComponentProps<FormDataT extends FieldValues> {
  /**
   * Classname to use for the action context component.
   */
  className?: string;

  /**
   * React-hook-form form methods.
   */
  formMethods: UseFormReturn<FormDataT>;
}

/**
 * Function used when setting general form feedback.
 */
export interface SetFormFeedback {
  (feedback: FormFeedbackTypes.FormFeedback): void;
}

/**
 * Callback function when a form is submitted.
 */
export interface OnSubmit<FormDataT> {
  (
    data: Readonly<FormDataT>,
    actionId: string,
    event: React.FormEvent<HTMLFormElement>,
    setError: UseFormSetError<FormDataT>,
    setFeedback: SetFormFeedback,
    /** If onSubmit was triggered by changing a specific form field, rather than e.g. a submit button, we should know the name of the field that triggered it */
    name?: Path<FormDataT>
  ): Promise<void> | void;
}

interface Props<FormDataT extends FieldValues> {
  /**
   * Specifies the details of rendering the form. Provides form id, namespace, collection of fields and action buttons
   * to use with the form.
   */
  formSpec: FormSpec<FormDataT> | UseInputEditFormReturn<FormDataT>;

  /**
   *
   * Callback function to call after valid submit.
   *
   * @callback
   * @param data data from the form submit
   * @param action form action that triggered submit
   * @param event the submit event
   * @param setError callback to set error on form fields
   * @param setFeedback callback to set form field feedback
   * @param name the name of the field that triggered the submit
   */
  onSubmit: OnSubmit<FormDataT>;

  /**
   * Whether to display form in inline mode or not.
   */
  inline?: boolean;

  /**
   * Submits form when the value of form field changes. Use's React's onChange event.
   * React's onChange is different form DOM's such that it fires on every change in the input field
   * whereas DOM's change event fires when the  field loses focus.
   *
   * If watchFields is specified, the form will debounce submit to the specified waitTime or 200ms for the fields specified
   * in watchFields only, and for all other fields the submit will be as soon as the value change happens. If no watchFields is
   * specified, the form will submit for every keypress on input fields and not when it loses focus for all the form fields.
   */
  submitOnChange?: DebounceProps<FormDataT>;

  /**
   * Component to render along side the action buttons for the form, if needed.
   */
  actionContextComponent?: React.FC<
    React.PropsWithChildren<ActionContextComponentProps<FormDataT>>
  >;

  /**
   * Callback function to call after invalid submit.
   *
   * @callback
   * @param errors error data from the form submit
   */
  onError?: (errors: FieldErrors<FormDataT>) => void;

  /**
   * Whether user confirmation is required when navigating away to another route while the form has unsaved changes
   */
  warnForUnsavedChanges?: boolean;

  /**
   * Display the form title
   */
  formTitle?: { value: string; as?: React.ElementType };

  /**
   * Since InputEditForm takes a generic type, we cannot directly use ForwardRef.
   * Instead we manually expose a parameter to pass in the ref to the form element.
   *
   * See more: https://stackoverflow.com/a/58473012
   */
  formRef?: Ref<HTMLFormElement>;

  /**
   * The reference of the form footer element.
   */
  formFooterRef?: Ref<HTMLDivElement>;

  /**
   * Displays below the form and above the ReCaptcha TOS when ReCaptcha is enabled.
   */
  children?: ReactNode;
}

interface InternalProps<FormDataT extends FieldValues> {
  inputEditFormReturn: UseInputEditFormReturn<FormDataT>;
  formProps: Omit<Props<FormDataT>, 'formSpec'>;
}

const InputEditFormInternal = <FormDataT extends FieldValues>({
  inputEditFormReturn: {
    renderFormSpec: { loading: schemaLoading, result: formSpec },
    methods,
    defaultValues
  },
  formProps: {
    onSubmit,
    submitOnChange,
    formRef,
    formFooterRef,
    formTitle,
    warnForUnsavedChanges,
    actionContextComponent,
    inline,
    onError,
    children
  }
}: InternalProps<FormDataT>): React.ReactElement => {
  const {
    formClassName,
    fieldsWrapperClassName,
    formTitleClassName,
    formFields: renderFormFields,
    formActionsSpec,
    fieldWatchers,
    formGroupFieldSeparator,
    formId: id,
    i18n,
    useReCaptcha: isUseReCaptcha = false,
    reCaptchaClassName
  } = formSpec;

  const { watch, handleSubmit, formState, setError } = methods;
  const { errors } = formState;

  const isActionButtonBarSticky =
    formActionsSpec.actionButtonBarPosition === FormActionButtonBarPosition.STICKY;
  const [formFeedbacks, setFormFeedbacks] = useState<FormFeedbackTypes.FormFeedback[]>([]);
  const reCaptchaResponse = useReCaptcha(isUseReCaptcha);
  const { addToast } = useToasts();
  const { formatMessage: localFormatMessage, loading: localTextLoading } = useTranslation(
    SharedComponent.INPUT_EDIT_FORM
  );
  const cx = useClassNameMapper(localStyles);
  const selectedFormAction = useRef<string>(null);
  const TitleHeading = formTitle?.as ?? 'h4';

  const tenant = useContext(TenantContext);
  const recaptchaScoreThreshold = tenant.publicConfig?.reCaptchaV3ScoreThreshold;

  /**
   * Called to update the formFeedBacks state
   *
   * @param feedback form feedback error message
   */
  const setFormFeedbackHandler = useCallback((feedback: FormFeedbackTypes.FormFeedback): void => {
    setFormFeedbacks([feedback]);
  }, []);

  /**
   * Called when the submit button is clicked on the form. It calls the callback onSubmit method.
   *
   * @callback
   * @param data data when the form is submitted.
   * @param event form submit event.
   * @param actionId action that triggers the form submit
   * @param name name of the field that triggered the form submit, if applicable
   */
  const onFormSubmit = useCallback(
    async (
      data: FormDataT,
      event: React.FormEvent<HTMLFormElement>,
      actionId: string,
      name?: Path<FormDataT>
    ) => {
      /**
       * When using ReCaptcha check and see if the score is above a specified
       * threshold, otherwise fail the form submit.
       */
      if (isUseReCaptcha && reCaptchaResponse) {
        const { success, score } = await reCaptchaResponse;
        if (!success || score < recaptchaScoreThreshold) {
          addToast({
            alertVariant: ToastAlertVariant.DANGER,
            id: `recaptcha-failed-${actionId}`,
            message: localFormatMessage('reCaptcha.error.message'),
            title: localFormatMessage('reCaptcha.error.title'),
            toastVariant: ToastVariant.BANNER
          });
          return null;
        }
      }

      setFormFeedbacks([]);
      const adjustedData = transformValue(data, renderFormFields);
      log.trace('Form submit values %o for form with id %s', adjustedData, id);
      return onSubmit(
        Object.freeze(adjustedData),
        actionId,
        event,
        setError,
        setFormFeedbackHandler,
        name
      );
    },
    [
      addToast,
      id,
      isUseReCaptcha,
      localFormatMessage,
      onSubmit,
      reCaptchaResponse,
      recaptchaScoreThreshold,
      renderFormFields,
      setError,
      setFormFeedbackHandler
    ]
  );

  /**
   * Called when the submit button is clicked on the form. It calls the callback onError method
   */
  const onInvalidSubmit = useCallback(async (): Promise<void> => {
    if (onError) {
      onError(errors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, onError]);

  useEffect(() => {
    let subscription;
    if (fieldWatchers) {
      subscription = watch((value, { name }) => {
        if (fieldWatchers.watchFields.includes(name)) {
          fieldWatchers.callback(transformValue(value, renderFormFields), methods);
        }
      });
    }

    return () => subscription?.unsubscribe();
  }, [fieldWatchers, methods, renderFormFields, watch]);

  if (schemaLoading || localTextLoading) {
    return null;
  }

  /**
   * Renders form group or form field based on the formFieldSpecDefinition.
   *
   * @param formFieldSpecDefinition formFieldSpecDefinition to render.
   * @param key key reference to generate unique key references for iterated items.
   */
  function renderFormFieldSpecDefinition(
    formFieldSpecDefinition: FormFieldSpecDefinition<FormDataT>,
    key: string
  ): React.ReactElement {
    if (isFormFieldType<FormDataT>(formFieldSpecDefinition)) {
      const { fieldVariant, name, className: fieldClassName } = formFieldSpecDefinition;
      const Field = registeredFields.get(fieldVariant);

      if (Field) {
        if (isPossibleValuesField(formFieldSpecDefinition)) {
          return (
            <PossibleValuesFieldDecorator<
              FieldPath<FormDataT>,
              FormDataT,
              PossibleValuesField<FieldPath<FormDataT>, FormDataT, FormFieldVariant>
            >
              key={`${id}-${key}-${name}`}
              fieldSpec={formFieldSpecDefinition}
              formId={id}
              i18n={i18n}
              className={cx('lia-form-field', fieldClassName)}
              allFields={renderFormFields}
              formMethods={methods}
              onFormSubmit={() =>
                handleSubmit((data: FormDataT, event?: React.FormEvent<HTMLFormElement>) => {
                  return onFormSubmit(data, event, 'submit', name);
                }, onInvalidSubmit)()
              }
              submitOnChange={submitOnChange}
              field={
                Field as React.ComponentType<
                  React.PropsWithChildren<
                    FormFieldProps<
                      FieldPath<FormDataT>,
                      FormDataT,
                      PossibleValuesField<FieldPath<FormDataT>, FormDataT, FormFieldVariant>
                    >
                  >
                >
              }
            />
          );
        } else {
          return (
            <Field
              key={`${id}-${key}-${name}`}
              fieldSpec={
                formFieldSpecDefinition as unknown as FormFieldType<
                  FieldPath<FieldValues>,
                  FieldValues,
                  FormFieldVariant
                >
              }
              formId={id}
              i18n={i18n}
              className={cx('lia-form-field', fieldClassName)}
              allFields={renderFormFields as FormFieldSpecDefinition<FieldValues>[]}
              formMethods={methods as UseFormReturn}
              onFormSubmit={() =>
                handleSubmit((data: FormDataT, event?: React.FormEvent<HTMLFormElement>) => {
                  return onFormSubmit(data, event, 'submit', name);
                }, onInvalidSubmit)()
              }
              submitOnChange={submitOnChange as DebounceProps<FieldValues>}
            />
          );
        }
      }
      log.warn('No registered field for field variant type [%s]', fieldVariant);
      return null;
    } else {
      const { id: formGroupId, className: formGroupClassName, type } = formFieldSpecDefinition;
      const adjustedClassName = cx(formGroupClassName, {
        'lia-form-field-group-divider':
          formGroupFieldSeparator === FormGroupFieldSeparator.DIVIDER &&
          type === FormGroupFieldType.FIELDSET
      });

      switch (formFieldSpecDefinition.type) {
        case FormGroupFieldType.FIELDSET: {
          const { items } = formFieldSpecDefinition;
          return (
            <FormGroupVisibilityHandler<FormDataT>
              key={formGroupId}
              allFields={renderFormFields}
              formMethods={methods}
              group={formFieldSpecDefinition}
            >
              <FormFieldset
                formId={id}
                i18n={i18n}
                fieldSetSpec={formFieldSpecDefinition}
                className={cx(adjustedClassName)}
              >
                {items.map(field =>
                  renderFormFieldSpecDefinition(field, `${formGroupId}-fieldset-item`)
                )}
              </FormFieldset>
            </FormGroupVisibilityHandler>
          );
        }
        case FormGroupFieldType.ROW: {
          const {
            items,
            as,
            props: { xs, sm, md, lg, xl, noGutters } = {}
          } = formFieldSpecDefinition;
          return (
            <FormGroupVisibilityHandler<FormDataT>
              key={formGroupId}
              allFields={renderFormFields}
              formMethods={methods}
              group={formFieldSpecDefinition}
            >
              <Row
                key={`${id}-row-${formGroupId}`}
                className={cx(adjustedClassName, 'lia-form-row')}
                as={as}
                xs={xs}
                sm={sm}
                md={md}
                lg={lg}
                xl={xl}
                noGutters={noGutters}
              >
                {items.map(field =>
                  renderFormFieldSpecDefinition(field, `${formGroupId}-row-item`)
                )}
              </Row>
            </FormGroupVisibilityHandler>
          );
        }
        case FormGroupFieldType.FORM_ROW: {
          const { items, as } = formFieldSpecDefinition;
          return (
            <FormGroupVisibilityHandler<FormDataT>
              allFields={renderFormFields}
              formMethods={methods}
              group={formFieldSpecDefinition}
              key={formGroupId}
            >
              <Form.Row
                key={`${id}-form-row-${formGroupId}`}
                className={cx(adjustedClassName)}
                as={as}
              >
                {items.map(field =>
                  renderFormFieldSpecDefinition(field, `${formGroupId}-form-row-item`)
                )}
              </Form.Row>
            </FormGroupVisibilityHandler>
          );
        }
        default: {
          const { props: { xs, sm, md, lg, xl } = {}, items, as } = formFieldSpecDefinition;
          return (
            <FormGroupVisibilityHandler<FormDataT>
              allFields={renderFormFields}
              formMethods={methods}
              group={formFieldSpecDefinition}
              key={formGroupId}
            >
              <Col
                key={`${id}-column-${formGroupId}`}
                className={cx(adjustedClassName)}
                as={as}
                xs={xs}
                sm={sm}
                md={md}
                lg={lg}
                xl={xl}
              >
                {items.map(field =>
                  renderFormFieldSpecDefinition(field, `${formGroupId}-col-item`)
                )}
              </Col>
            </FormGroupVisibilityHandler>
          );
        }
      }
    }
  }

  /**
   * Hack to workaround this issue:
   *
   * https://github.com/react-hook-form/react-hook-form/discussions/3704#discussioncomment-265857
   *
   * @param event the submit event
   */
  function handleSubmitWrapper(event) {
    event.stopPropagation();
    handleSubmit((data: FormDataT, submitEvent?: React.FormEvent<HTMLFormElement>) => {
      return onFormSubmit(data, submitEvent, selectedFormAction.current);
    }, onInvalidSubmit)(event);
  }

  /**
   * Renders the form.
   */
  function renderForm(): React.ReactElement {
    const renderedFormFields = renderFormFields.map(spec =>
      renderFormFieldSpecDefinition(spec, 'fields')
    );

    return (
      <>
        <FormFeedbacks position={FormFeedbackPosition.TOP} formFeedbacks={formFeedbacks} />
        <Form
          inline={inline}
          onSubmit={handleSubmitWrapper}
          className={cx(formClassName, { 'lia-g-page-full-height': isActionButtonBarSticky })}
          data-testid={`InputEditForm.${id}`}
          ref={formRef}
        >
          {formTitle && (
            <TitleHeading className={cx('lia-form-title', formTitleClassName)}>
              {formTitle.value}
            </TitleHeading>
          )}
          {fieldsWrapperClassName || isActionButtonBarSticky ? (
            <div
              className={cx(
                { 'lia-fields-wrapper': isActionButtonBarSticky },
                fieldsWrapperClassName
              )}
            >
              {renderedFormFields}
            </div>
          ) : (
            renderedFormFields
          )}
          {(formActionsSpec.formActions.length > 0 || actionContextComponent) && (
            <FormActionButtons<FormDataT>
              formFooterRef={formFooterRef}
              actionContextComponent={actionContextComponent}
              formId={id}
              formMethods={methods}
              defaultValues={defaultValues}
              formActionsSpec={formActionsSpec}
              onClick={(actionId, event) => {
                selectedFormAction.current = actionId;
                if (actionId === 'cancel') {
                  onSubmit(null, 'cancel', event, setError, setFormFeedbackHandler);
                }
              }}
              i18n={i18n}
            />
          )}

          <FormFeedbacks position={FormFeedbackPosition.BOTTOM} formFeedbacks={formFeedbacks} />
        </Form>
        {children}
        {isUseReCaptcha && <ReCaptchaPrivacyTos className={reCaptchaClassName} />}
        {warnForUnsavedChanges && (
          <UnsavedChangedDialog
            formMethods={methods}
            defaultValues={defaultValues}
            id={id}
            i18n={i18n}
          />
        )}
      </>
    );
  }

  return renderForm();
};

interface InputEditFormFromFormSpecProps<FormDataT extends FieldValues> {
  formSpec: FormSpec<FormDataT>;
  formProps: Omit<Props<FormDataT>, 'formSpec'>;
}
const InputEditFormFromFormSpec = <FormDataT extends FieldValues>({
  formProps,
  formSpec
}: InputEditFormFromFormSpecProps<FormDataT>): React.ReactElement => {
  const inputEditFormReturn: UseInputEditFormReturn<FormDataT> = useInputEditForm(formSpec);
  return <InputEditFormInternal inputEditFormReturn={inputEditFormReturn} formProps={formProps} />;
};

function isUseInputEditFormReturn<FormDataT extends FieldValues>(
  spec: FormSpec<FormDataT> | UseInputEditFormReturn<FormDataT>
): spec is UseInputEditFormReturn<FormDataT> {
  return (spec as UseInputEditFormReturn<FormDataT>).defaultValues !== undefined;
}
/**
 * Renders form based on the specified parameters.
 *
 * Note: if the component which renders the InputEditForm changes the form via the formSpec prop without unmounting
 * the InputEditForm first, consider wrapping the InputEditForm in a React.Fragment that uses a key matching the form id
 *
 * This will create a new instance of the InputEditForm and will likely alleviate problems surrounding the form state
 *
 * @author Manish Shrestha
 */
const InputEditForm = <FormDataT extends FieldValues>(
  props: Props<FormDataT>
): React.ReactElement => {
  const { formSpec, ...rest } = props;
  if (isUseInputEditFormReturn(formSpec)) {
    if (formSpec.renderFormSpec.loading) {
      return null;
    }
    const {
      renderFormSpec: {
        result: { formId }
      }
    } = formSpec;
    return <InputEditFormInternal key={formId} inputEditFormReturn={formSpec} formProps={rest} />;
  } else {
    const { formId } = formSpec;
    return <InputEditFormFromFormSpec key={formId} formSpec={formSpec} formProps={rest} />;
  }
};

export default InputEditForm;
