import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { FormFeedbackType } from '../../../helpers/form/FormFieldFeedback/enums';
import type FormFeedbackTypes from '../../../helpers/form/FormFieldFeedback/form-feedback-types';
import BannerFormAlert from '../../common/BannerFormAlert/BannerFormAlert';
import FormFieldFeedback from '../FormFieldFeedback/FormFieldFeedback';
import localStyles from './FormFeedback.module.pcss';

interface Props {
  /**
   * Feedback message props.
   */
  feedback: FormFeedbackTypes.FormFeedback;
  /**
   * Class name for the Form Feedback
   */
  className?: string;
}

/**
 *
 * Checks for the position of the form feedback and renders the feedback accordingly.
 *
 * @author Neha Anandpara
 */
const FormFeedback: React.FC<React.PropsWithChildren<Props>> = ({ feedback, className }) => {
  const { message, variant } = feedback;
  const cx = useClassNameMapper(localStyles);
  if (variant.type === FormFeedbackType.BANNER) {
    const { alertVariant } = variant;
    return (
      <BannerFormAlert
        alertMessage={message}
        alertVariant={alertVariant}
        testId={`FormFeedback.${feedback.variant.type}`}
      />
    );
  } else if (feedback.variant.type === FormFeedbackType.INLINE) {
    const { valid } = variant;
    return (
      <FormFieldFeedback
        message={message}
        valid={valid}
        className={cx(className, 'lia-text-style')}
      />
    );
  } else {
    return null;
  }
};

export default FormFeedback;
