import React from 'react';
import FormFeedback from '../FormFeedback/FormFeedback';
import type FormFeedbackTypes from '../../../helpers/form/FormFieldFeedback/form-feedback-types';
import type { FormFeedbackPosition } from '../../../helpers/form/FormFieldFeedback/enums';

interface Props {
  /**
   * position of the form feedback message to display, at the top as a alert box or at the bottom as a message.
   */
  position: FormFeedbackPosition;

  /**
   * List of form feedbacks.
   */
  formFeedbacks: FormFeedbackTypes.FormFeedback[];
}

/**
 *
 * Iterate through all the feedback messages and renders FormFeedback component accordingly.
 *
 * @author Neha Anandpara
 */
const FormFeedbacks: React.FC<React.PropsWithChildren<Props>> = ({ position, formFeedbacks }) => {
  return (
    <>
      {formFeedbacks
        ?.filter(formFeedback => formFeedback.position === position)
        .map(formFeedback => (
          <FormFeedback key={formFeedback.message} feedback={formFeedback} />
        ))}
    </>
  );
};

export default FormFeedbacks;
