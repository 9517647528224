import { createObjectByPath } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import { getLog } from '@aurora/shared-utils/log';
import isEqual from 'react-fast-compare';
import type { DefaultValues, FieldValues, UseFormReturn } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { FieldPath, Path } from 'react-hook-form/dist/types/path';
import {
  isCategorizedPossibleValuesField,
  isPossibleValuesField,
  performActionOnFormFieldSpec
} from '../../../helpers/form/FormHelper/FormHelper';
import type { FormFieldVariant } from '../enums';
import type { FormFieldSpecDefinition, FormSpec, PossibleValue, PossibleValueType } from '../types';
import useFormSpec from './useFormSpec';

const log = getLog(module);

/**
 * gets default value from the form spec.
 * @param formFields
 * @param formId
 */
function getDefaultValues<FormDataT extends FieldValues>(
  formFields: FormFieldSpecDefinition<FormDataT>[],
  formId: String
): FormDataT {
  const defaultValues = {} as FormDataT;
  formFields.forEach(fieldDefinition => {
    performActionOnFormFieldSpec(fieldDefinition, formFieldSpec => {
      const { name, defaultValue } = formFieldSpec;

      let value: unknown = defaultValue;

      if (isPossibleValuesField(formFieldSpec)) {
        const values: PossibleValueType<
          FormFieldVariant,
          Path<FormDataT>,
          FormDataT
        > = isCategorizedPossibleValuesField(formFieldSpec.values)
          ? formFieldSpec.values.flatMap<
              PossibleValue<
                | FormDataT[Path<FormDataT>]
                | (FormDataT[Path<FormDataT>] extends (infer TypeT)[] ? TypeT : never),
                FormDataT
              >
            >(({ values: possibleValues }) => possibleValues)
          : formFieldSpec.values;
        if (Array.isArray(defaultValue)) {
          value = values
            .filter(option => {
              const { value: optionValue } = option;
              return defaultValue.includes(optionValue);
            })
            .map(({ key }) => key) as FormDataT[FieldPath<FormDataT>];
        } else {
          const defaultPossibleValue = values.find(option => {
            const { value: optionValue } = option;
            return isEqual(optionValue, value);
          });
          if (defaultPossibleValue !== undefined) {
            value = defaultPossibleValue.key as FormDataT[FieldPath<FormDataT>];
          }
        }
      }
      createObjectByPath(defaultValues, name, value);
    });
  });

  formFields.forEach(fieldDefinition => {
    performActionOnFormFieldSpec(fieldDefinition, formFieldSpec => {
      const { name, isVisible } = formFieldSpec;
      if (isVisible) {
        const { callback } = isVisible;
        if (callback(defaultValues) && defaultValues[name] === undefined) {
          log.error('default value is undefined for %s field', name);
        } else if (defaultValues[name] === undefined) {
          delete defaultValues[name];
        }
      } else if (defaultValues[name] === undefined) {
        delete defaultValues[name];
        log.error('default value is undefined for %s field', name);
      }
    });
  });
  log.debug('Default values for [%s] form: %o', formId, defaultValues);
  return defaultValues;
}

export interface UseInputEditFormReturn<FormDataT extends FieldValues> {
  renderFormSpec: {
    loading: boolean;
    result: FormSpec<FormDataT>;
  };
  methods: UseFormReturn<FormDataT>;
  defaultValues: FormDataT;
}

/**
 * Sets up default values, form schema and returns form methods and form spec after merging with the schema. Use this
 * when more control is desired for form states based on external factors.
 * @param formSpec form spec for the form
 * @author Manish Shrestha
 */
export function useInputEditForm<FormDataT extends FieldValues>(
  formSpec: FormSpec<FormDataT>
): UseInputEditFormReturn<FormDataT> {
  const { formId: id, revalidateMode = 'onChange', shouldUnregister = true } = formSpec;
  const [schemaLoading, renderFormSpec] = useFormSpec(formSpec);

  const { formFields } = renderFormSpec;
  const defaultValues: FormDataT = getDefaultValues(formFields, id);
  const methods: UseFormReturn<FormDataT> = useForm<FormDataT>({
    reValidateMode: revalidateMode,
    defaultValues: defaultValues as DefaultValues<FormDataT>,
    shouldUnregister
  });

  return {
    renderFormSpec: {
      loading: schemaLoading,
      result: renderFormSpec
    },
    methods,
    defaultValues
  };
}
