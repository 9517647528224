import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import Icons from '../../../icons';
import AppTypeContext from '../../context/AppTypeContext';
import { IconColor, IconSize } from '../Icon/enums';
import Icon from '../Icon/Icon';
import localStyles from './BannerFormAlert.module.pcss';
import { AppType } from '@aurora/shared-types/app';

export enum BannerFormAlertVariant {
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
  SUCCESS = 'success'
}

export interface BannerFormAlertProps {
  /**
   * Alert message to display
   */
  alertMessage: string;

  /**
   * Variant of the alert to display.
   */
  alertVariant?: BannerFormAlertVariant;

  /**
   * A data-testid to apply to the banner.
   */
  testId?: string;

  /**
   * The CSS class name(s) that will be applied to the component
   */
  className?: string;
}

/**
 * Inline alert component to be used as feedback on forms
 *
 * @author Andre Almeida
 */
const BannerFormAlert: React.FC<React.PropsWithChildren<BannerFormAlertProps>> = ({
  alertMessage,
  alertVariant = BannerFormAlertVariant.DANGER,
  testId,
  className
}) => {
  const cx = useClassNameMapper(localStyles);
  const appType = useContext(AppTypeContext);
  const getBannerFormAlertIcon = () =>
    ({
      [BannerFormAlertVariant.INFO]: Icons.InfoIcon,
      [BannerFormAlertVariant.WARNING]: Icons.FlagIcon,
      [BannerFormAlertVariant.DANGER]: Icons.DangerIcon,
      [BannerFormAlertVariant.SUCCESS]: Icons.CheckmarkIcon
    }[alertVariant] || Icons.DangerIcon);

  const getBannerFormAlertAdminIcon = () =>
    ({
      [BannerFormAlertVariant.INFO]: Icons.InfoAdminIcon,
      [BannerFormAlertVariant.WARNING]: Icons.FlagAdminIcon,
      [BannerFormAlertVariant.DANGER]: Icons.DangerAdminIcon,
      [BannerFormAlertVariant.SUCCESS]: Icons.CheckmarkAdminIcon
    }[alertVariant] || Icons.DangerAdminIcon);

  const getIconColorByVariant = () =>
    ({
      [BannerFormAlertVariant.INFO]: IconColor.INFO,
      [BannerFormAlertVariant.WARNING]: IconColor.WARNING,
      [BannerFormAlertVariant.DANGER]: IconColor.DANGER,
      [BannerFormAlertVariant.SUCCESS]: IconColor.SUCCESS
    }[alertVariant || IconColor.DANGER]);

  return (
    <span
      data-testid={testId}
      className={cx(
        `lia-alert-container lia-alert-container-${alertVariant} rounded-sm`,
        className
      )}
    >
      <div className={cx('lia-alert-icon-wrap')}>
        <Icon
          icon={
            appType === AppType.ADMIN ? getBannerFormAlertAdminIcon() : getBannerFormAlertIcon()
          }
          color={getIconColorByVariant()}
          size={IconSize.PX_16}
        />
      </div>
      <span className={cx('lia-alert-message')}>{alertMessage}</span>
    </span>
  );
};

export default BannerFormAlert;
