import React from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';
import type { FieldPath } from 'react-hook-form/dist/types/path';
import { isFieldVisible, isFormFieldType } from '../../../helpers/form/FormHelper/FormHelper';
import type { FormFieldVariant } from '../enums';
import type {
  FormFieldGroupSpecDefinition,
  FormFieldSpecDefinition,
  FormFieldType,
  WatchFields
} from '../types';
import { useFormWatch } from '../useFormWatch';

interface Props<FormDataT extends FieldValues> {
  /**
   * The group spec to render as the children
   */
  group: FormFieldGroupSpecDefinition<FormDataT>;
  /**
   * Form methods
   */
  formMethods: UseFormReturn<FormDataT>;
  /**
   * All fields of the form
   */
  allFields: Array<FormFieldSpecDefinition<FormDataT>>;
  /**
   * The group to render
   */
  children: React.ReactNode;
}

/**
 * Gets all the fields in the specified form field group.
 * @param group group to get the fields from.
 */
function getFieldsInGroup<FormDataT extends FieldValues>(
  group: FormFieldGroupSpecDefinition<FormDataT>
): Array<FormFieldType<FieldPath<FieldValues>, FieldValues, FormFieldVariant>> {
  return group.items.flatMap(item => {
    if (isFormFieldType(item)) {
      return [item];
    } else {
      return getFieldsInGroup(item);
    }
  });
}

/**
 * Renders the group only if all the fields within the group is visible else does not render the group.
 * @author Manish Shrestha
 */
const FormGroupVisibilityHandler = <FormDataT extends FieldValues>({
  group,
  formMethods,
  allFields,
  children
}: Props<FormDataT>): React.ReactElement => {
  const fieldsInGroup = getFieldsInGroup(group);
  const visibilityWatchFields: Array<WatchFields<FormDataT>> = fieldsInGroup
    .filter(field => field?.isVisible?.watchFields)
    .map(field => field.isVisible.watchFields) as WatchFields<FormDataT>[];

  const watchAll = visibilityWatchFields.includes('all');
  const watchFields = visibilityWatchFields.filter(watchField => watchField !== 'all').flat();

  const fieldsToWatch: WatchFields<FormDataT> = watchAll
    ? 'all'
    : ([...new Set(watchFields)] as FieldPath<FormDataT>[]);

  const { control } = formMethods;
  const watchedValuesObject: Partial<FormDataT> = useFormWatch(fieldsToWatch, control);

  if (!isFieldVisible<FormDataT>(group, watchedValuesObject, allFields)) {
    return null;
  }

  return <>{children}</>;
};

export default FormGroupVisibilityHandler;
