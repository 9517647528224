import { createGlobalState } from 'react-hooks-global-state';
import type { ReCaptchaResponse } from '../../components/form/ReCaptchaResponseType';

interface RecaptchaState {
  /**
   * The ReCaptcha score, may be null if ReCaptcha is not used.
   */
  reCaptchaResponse: Promise<ReCaptchaResponse> | null;
}

const { useGlobalState: useRecaptchaGlobalState } = createGlobalState<RecaptchaState>({
  reCaptchaResponse: null
});

export default useRecaptchaGlobalState;
