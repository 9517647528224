import { useContext, useEffect } from 'react';
import { getLog } from '@aurora/shared-utils/log';
import { postToInternalApi } from '../../helpers/ApiHelper';
import useRecaptchaGlobalState from '../../helpers/ui/RecaptchaGlobalState';
import TenantContext from '../context/TenantContext';
import type { ReCaptchaResponse } from './ReCaptchaResponseType';

const log = getLog(module);

/**
 * Redefine the Window object to include the grecaptcha object added to the
 * page by the inclusion of the ReCaptcha v3 library.
 */
declare global {
  interface Window {
    grecaptcha: ReCaptchaV2.ReCaptcha;
  }
}

/**
 * Call internal API to convert the token generated by the ReCaptcha
 * client into a score.
 *
 * @param token the token generated by the ReCaptcha client.
 */
async function getScore(token: string): Promise<ReCaptchaResponse> {
  const response = await postToInternalApi('/api/recaptcha', { token });
  return response.json();
}

/**
 * Get a token from the ReCaptcha client.
 */
async function getToken(key: string): Promise<string> {
  const { grecaptcha } = window;
  return grecaptcha.execute(key, {
    action: 'verify'
  });
}

/**
 * Add ReCaptcha Script to page
 * @param siteKey a Recaptcha site key
 */
function addScript(siteKey: string): Promise<void> {
  return new Promise<void>(resolve => {
    const [elementToInjectScript] = document.querySelectorAll('head');
    const sourceUrl = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
    const scriptElement = document.createElement('script');

    scriptElement.setAttribute('src', sourceUrl);
    scriptElement.setAttribute('async', 'true');
    scriptElement.addEventListener('load', () => {
      const { grecaptcha } = window;
      grecaptcha.ready(() => {
        resolve();
      });
    });

    elementToInjectScript.append(scriptElement);
  });
}

/**
 * Add ReCaptcha script, generate a token, and get the score.
 * @param siteKey a Recaptcha site key
 */
async function addScriptAndGetScore(siteKey: string): Promise<ReCaptchaResponse> {
  await addScript(siteKey);
  const token = await getToken(siteKey);
  return getScore(token);
}

/**
 * A hook to get a ReCaptcha score for the current user. This will return a score
 * between 0 and 1 back from Google's ReCaptcha API where 0 is assumed to be a bot
 * and 1 is assumed to be a human. Uses the global state to store a promise to the
 * score that encapsulates adding the script, generating the token, and getting
 * a score for the token with the assumption that the score only needs to be determined
 * once per request-scope.
 *
 * @param enabled whether recaptcha is enabled.
 *
 * @author Corey Aing, Adam Ayres
 */
export default function useReCaptcha(enabled): Promise<ReCaptchaResponse> {
  const [reCaptchaResponse, setReCaptchaResponse] = useRecaptchaGlobalState('reCaptchaResponse');
  const tenant = useContext(TenantContext);
  const siteKey = tenant.publicConfig?.reCaptchaV3SiteKey;
  const featureEnabled = tenant.publicConfig?.reCaptchaV3Enabled;
  const showWarnings = !(process.env.NODE_ENV === 'development');
  if (featureEnabled && !siteKey && showWarnings) {
    log.warn('ReCaptcha enabled but no site key configured');
  } else if (!featureEnabled && showWarnings) {
    log.warn('ReCaptcha is disabled.');
  }

  useEffect(() => {
    if (enabled && siteKey && featureEnabled && reCaptchaResponse === null) {
      setReCaptchaResponse(addScriptAndGetScore(siteKey));
    }
  }, [enabled, siteKey, featureEnabled, reCaptchaResponse, setReCaptchaResponse]);

  return reCaptchaResponse;
}
