import type { I18n } from '@aurora/shared-types/texts';
import { isLeftEqual } from '@aurora/shared-utils/helpers/objects/ObjectHelper';
import type { FormEvent, Ref } from 'react';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { ClassNamesFnWrapper } from 'react-bootstrap/lib/esm/createClassNames';
import type { FieldValues } from 'react-hook-form';
import type { FormState, UseFormReturn } from 'react-hook-form/dist/types';
import Button from '../../common/Button/Button';
import { ButtonVariant, LoadingButtonVariant } from '../../common/Button/enums';
import { FormActionButtonBarPosition, FormActionButtonsPosition, FormButtonType } from '../enums';
import type { ActionContextComponentProps } from '../InputEditForm/InputEditForm';
import type { FormAction, FormActions } from '../types';
import { useFormWatch } from '../useFormWatch';
import localStyles from './FormActionButtons.module.pcss';

/**
 * Gets the button type for the specified action id of the form. All action id besides reset and cancel will return
 * a submit button type.
 *
 * @param actionId action that triggers the form submit.
 */
function getButtonType(actionId: string): FormButtonType {
  if (actionId === 'reset' || actionId === 'cancel') {
    return FormButtonType.BUTTON;
  }
  return FormButtonType.SUBMIT;
}

interface FormButtonsProps<FormDataT extends FieldValues> {
  /**
   * form id
   */
  formId: string;
  /**
   * form actions
   */
  formActions: FormAction<FormDataT>[];
  /**
   * cx for styles
   */
  cx: ClassNamesFnWrapper;
  /**
   * form methods
   */
  formMethods: UseFormReturn<FormDataT>;
  /**
   * form default values
   */
  defaultValues: FormDataT;
  /**
   * i18n of the component
   */
  i18n: I18n<unknown, unknown>;
  /**
   * @callback onClick handler when a button is clicked. Only triggered if `button.onClick` is not specified
   * @param actionId action id
   * @param event event
   * @param formState the form state
   */
  onClick: (actionId: string, event: FormEvent, formState: FormState<FormDataT>) => void;
  /**
   * form action button is sticky
   */
  isSticky?: boolean;
  /**
   * classname
   */
  className?: string;
}

/**
 *
 * Renders the form buttons
 * @author Manish Shrestha
 */
const FormButtons = <FormDataT extends FieldValues>({
  formActions,
  cx,
  formMethods: { formState, reset, getValues },
  isSticky,
  className: formActionsClassName,
  defaultValues,
  i18n,
  formId: id,
  onClick
}: FormButtonsProps<FormDataT>) => {
  const { isSubmitting } = formState;
  const { formatMessage, loading: textLoading } = i18n;

  if (textLoading) {
    return null;
  }

  return (
    formActions.length > 0 && (
      <div
        className={cx('lia-form-actions', formActionsClassName, {
          'lia-form-actions-sticky': isSticky
        })}
      >
        {formActions.map(action => {
          const { actionId, buttonSpec } = action;
          return (
            <Button
              key={actionId}
              active={buttonSpec?.active}
              as={buttonSpec?.as}
              block={buttonSpec?.block}
              type={getButtonType(actionId)}
              variant={buttonSpec?.variant || ButtonVariant.PRIMARY}
              className={cx('lia-form-actions-btn', buttonSpec?.className)}
              size={buttonSpec?.size}
              href={buttonSpec?.href}
              disabled={buttonSpec?.disabled || isSubmitting}
              loading={
                buttonSpec?.buttonType === LoadingButtonVariant.LOADING_BUTTON && isSubmitting
              }
              onClick={(event: React.MouseEvent) => {
                if (actionId === 'reset') {
                  reset(defaultValues);
                }
                if (buttonSpec?.onClick) {
                  buttonSpec.onClick(getValues() as FormDataT, event, formState);
                } else {
                  onClick(actionId, event, formState);
                }
              }}
              data-testid={`InputEditForm.${id}.action.${actionId}`}
            >
              {buttonSpec?.title ?? formatMessage(`${id}.${actionId}`)}
            </Button>
          );
        })}
      </div>
    )
  );
};

interface Props<FormDataT extends FieldValues> {
  /**
   * id of the form
   */
  formId: string;
  /**
   * form methods
   */
  formMethods: UseFormReturn<FormDataT>;
  /**
   * action context component
   */
  actionContextComponent?: React.FC<
    React.PropsWithChildren<ActionContextComponentProps<FormDataT>>
  >;
  /**
   * form footer ref
   */
  formFooterRef?: Ref<HTMLDivElement>;
  /**
   * form default values
   */
  defaultValues: FormDataT;
  /**
   * form action button specs
   */
  formActionsSpec: FormActions<FormDataT>;
  /**
   * @callback onClick callback when any action button is clicked, if it does not have onClick handler specified.
   * @param actionId action id
   * @param event event
   */
  onClick: (actionId: string, event: FormEvent<HTMLFormElement>) => void;
  /**
   * i18n to use for the localized text.
   */
  i18n: I18n<unknown, unknown>;
}

/**
 * Renders the form action buttons
 * @author Manish Shrestha
 */
const FormActionButtons = <FormDataT extends FieldValues>({
  formMethods,
  actionContextComponent: ActionContextComponent,
  formId: id,
  defaultValues,
  formActionsSpec,
  formFooterRef,
  i18n,
  onClick
}: Props<FormDataT>): React.ReactElement => {
  const {
    formActions,
    actionButtonBarPosition,
    actionButtonsPosition,
    outerContainerClassName: formActionsOuterContainerClassName,
    innerContainerClassName: formActionsInnerContainerClassName,
    className: formActionClassName
  } = formActionsSpec;

  const { control } = formMethods;
  const currentValues = useFormWatch('all', control);
  const isHideActionButtons = isLeftEqual(currentValues, defaultValues);

  const cx = useClassNameMapper(localStyles);

  const isActionButtonBarSticky = actionButtonBarPosition === FormActionButtonBarPosition.STICKY;
  return (
    <div
      className={cx(
        isActionButtonBarSticky
          ? 'lia-form-actions-outer-container-sticky'
          : 'lia-form-actions-outer-container',
        {
          'd-sm-none': isActionButtonBarSticky && isHideActionButtons && !ActionContextComponent
        },
        formActionsOuterContainerClassName
      )}
      ref={formFooterRef}
    >
      <div
        className={cx(
          `lia-form-actions-inner-container lia-form-actions-align-${actionButtonsPosition}`,
          formActionsInnerContainerClassName,
          {
            'lia-form-actions-inner-container-sticky container': isActionButtonBarSticky
          }
        )}
      >
        {actionButtonsPosition === FormActionButtonsPosition.LEFT ? (
          <>
            <FormButtons
              formActions={formActions}
              cx={cx}
              formMethods={formMethods}
              defaultValues={defaultValues}
              i18n={i18n}
              formId={id}
              onClick={onClick}
              isSticky={isActionButtonBarSticky}
              className={formActionClassName}
            />
            {!!ActionContextComponent && (
              <ActionContextComponent
                className={cx('lia-form-action-context')}
                formMethods={formMethods}
              />
            )}
          </>
        ) : (
          <>
            {!!ActionContextComponent && (
              <ActionContextComponent
                className={cx('lia-form-action-context')}
                formMethods={formMethods}
              />
            )}
            <FormButtons
              formActions={formActions}
              cx={cx}
              formMethods={formMethods}
              defaultValues={defaultValues}
              i18n={i18n}
              formId={id}
              isSticky={isActionButtonBarSticky}
              onClick={onClick}
              className={formActionClassName}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default FormActionButtons;
