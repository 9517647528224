import type { MutableRefObject } from 'react';
import React, { useId, useRef, useState } from 'react';
import { Form, Modal, useClassNameMapper } from 'react-bootstrap';
import { SharedComponent } from '../../../enums';
import FormFieldFeedback from '../../form/FormFieldFeedback/FormFieldFeedback';
import useTranslation from '../../useTranslation';
import Button from '../Button/Button';
import { ButtonVariant, LoadingButtonVariant } from '../Button/enums';
import localStyles from './ConfirmationDialog.module.pcss';

interface UseCheckboxConfirmOptions {
  /**
   * The title to display before the checkbox in the dialog.
   */
  title: string;
  /**
   * The label to display next to the checkbox in the dialog.
   */
  label: string;
}

interface Props {
  /**
   * Class name(s) to apply to the modal.
   */
  className?: string;
  /**
   * Whether the modal is currently being displayed.
   */
  show: boolean;
  /**
   * @callback fired when the close button or backdrop is clicked. If not provided, onCancel() will be called.
   */
  onHide?(): void;
  /**
   * @callback fired when the primary submit button is clicked.
   */
  onSubmit(done: (submitting: boolean) => void): void;
  /**
   * @callback fired when the cancel button is clicked.
   */
  onCancel: () => void;
  /*
   * @callback fired when the primary esc key is pressed.
   */
  onEscapeKeyDown?: (event: KeyboardEvent) => void;
  /**
  /**
   * Text added to the <Modal.Title>.
   */
  titleText: string;
  /**
   * Text added to the <Modal.Body>.
   */
  bodyText: string | React.ReactElement;
  /**
   * The React Bootstrap button variant used for the primary submit button.
   */
  submitButtonVariant?: ButtonVariant;
  /**
   * The submit button type.
   */
  submitButtonType?: LoadingButtonVariant;
  /**
   * Text added to the submit button.
   */
  submitButtonText?: string;
  /**
   * Text added to the cancel button.
   */
  cancelButtonText?: string;
  /**
   * The variant of the modal backdrop.
   */
  backdrop?: true | false | 'static';
  /**
   * Class name(s) to apply as an optional extra class name to .modal-backdrop.
   */
  backdropClassName?: string;
  /**
   * Container where the modal should display, if not specified the body will be used.
   */
  container?: MutableRefObject<HTMLElement>;
  /**
   * A boolean flag indicating whether to use checkbox confirmation.
   */
  useCheckboxConfirm?: UseCheckboxConfirmOptions;
}

/**
 * The dialog variant of a modal. Contains title text, body text, submit button, and cancel button.
 *
 * @author Jonathan Bridges
 */
const ConfirmationDialog: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  show,
  onHide,
  onSubmit,
  onCancel,
  onEscapeKeyDown,
  titleText,
  bodyText,
  submitButtonVariant = ButtonVariant.PRIMARY,
  submitButtonText,
  submitButtonType = LoadingButtonVariant.STANDARD,
  cancelButtonText,
  backdrop = true,
  backdropClassName,
  children,
  container,
  useCheckboxConfirm
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    SharedComponent.CONFIRMATION_DIALOG
  );
  const cx = useClassNameMapper(localStyles);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const checkboxRef = useRef(null);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const uid = useId();

  if (textLoading) {
    return null;
  }

  function handleSubmitClick(): void {
    if (useCheckboxConfirm) {
      if (!checked) {
        setHasErrors(true);
        checkboxRef.current.focus();
        return;
      }
      setHasErrors(false);
    }
    setSubmitting(true);
    onSubmit(submittingState => setSubmitting(submittingState));
  }

  /**
   * Function to handle hide/cancel modal action
   */
  function handleHide() {
    if (!submitting) {
      if (onHide) {
        onHide();
      } else {
        onCancel();
      }
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleHide}
      size="sm"
      centered
      aria-labelledby={uid}
      backdrop={backdrop}
      backdropClassName={backdropClassName}
      onEscapeKeyDown={onEscapeKeyDown}
      data-testid="ConfirmationDialog"
      container={container}
      className={cx(className, 'lia-modal')}
      style={{ pointerEvents: 'auto' }}
    >
      <Modal.Header className={cx('lia-header')}>
        <Modal.Title as="h2" className={cx('lia-title')} id={uid}>
          {titleText}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={cx('lia-body')} data-testid="ConfirmationDialog.Body">
        <span className={cx('d-block')}>{bodyText}</span>
        {useCheckboxConfirm && (
          <Form.Group
            controlId="formBasicCheckbox"
            className={cx('d-flex flex-column justify-content-center align-items-center')}
          >
            <p>{useCheckboxConfirm.title}</p>
            <Form.Check
              type="checkbox"
              data-testid="ConfirmDialog.Checkbox"
              ref={checkboxRef}
              label={useCheckboxConfirm.label}
              className={cx('lia-g-pt-15')}
              checked={checked}
              aria-describedby="checkFormBasicCheckbox"
              onChange={() => setChecked(!checked)}
            />
            {hasErrors && (
              <FormFieldFeedback
                id="checkFormBasicCheckbox"
                message={formatMessage('useCheckboxConfirmError')}
                valid={false}
                className={cx('lia-g-px-15')}
              />
            )}
          </Form.Group>
        )}
        {children}
        <section className={cx('lia-footer')}>
          <Button
            size="lg"
            variant={submitButtonVariant}
            onClick={handleSubmitClick}
            data-testid="ConfirmationDialog.ConfirmButton"
            loading={submitButtonType === LoadingButtonVariant.LOADING_BUTTON && submitting}
          >
            {submitButtonText || formatMessage('submit')}
          </Button>
          <Button
            size="lg"
            variant={ButtonVariant.LIGHT}
            onClick={onCancel}
            data-testid="ConfirmationDialog.CancelButton"
            disabled={submitButtonType === LoadingButtonVariant.LOADING_BUTTON && submitting}
          >
            {cancelButtonText || formatMessage('cancel')}
          </Button>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationDialog;
