import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { FieldValues } from 'react-hook-form';
import type { FieldPath, Path } from 'react-hook-form/dist/types/path';
import {
  isCategorizedPossibleValuesField,
  transformValue
} from '../../../helpers/form/FormHelper/FormHelper';
import type { FormFieldVariant } from '../enums';
import type { FormFieldProps, PossibleValuesField } from '../types';
import { useFormWatch } from '../useFormWatch';

interface Props<
  NameT extends FieldPath<FormDataT>,
  FormDataT extends FieldValues,
  FieldT extends PossibleValuesField<NameT, FormDataT, FormFieldVariant>
> extends FormFieldProps<NameT, FormDataT, FieldT> {
  /**
   * The field to render
   */
  field: React.ComponentType<React.PropsWithChildren<FormFieldProps<NameT, FormDataT, FieldT>>>;
}

/**
 * Decorates the possible value fields to ensure that only visible options are rendered.
 * @author Manish Shrestha
 */
const PossibleValuesFieldDecorator = <
  NameT extends FieldPath<FormDataT>,
  FormDataT extends FieldValues,
  FieldT extends PossibleValuesField<NameT, FormDataT, FormFieldVariant>
>({
  fieldSpec,
  formMethods,
  allFields,
  formId,
  i18n,
  className,
  onFormSubmit,
  submitOnChange,
  field: Field
}: Props<NameT, FormDataT, FieldT>): React.ReactElement => {
  const { values } = fieldSpec;
  const { control } = formMethods;
  const cx = useClassNameMapper();

  const possibleValueWatchFields: Set<Path<FormDataT>> = isCategorizedPossibleValuesField(values)
    ? new Set(
        values.flatMap<Path<FormDataT>>(({ values: possibleValues }) => {
          return (
            possibleValues
              .filter(({ isVisible: isPossibleValueVisible }) => isPossibleValueVisible)
              .map(
                ({ isVisible: isPossibleValueVisible }) => isPossibleValueVisible.watchFields ?? []
              )
              // eslint-disable-next-line unicorn/no-array-reduce
              .reduce((previousValue, currentValue) => {
                return [...previousValue, ...currentValue];
              }, [])
          );
        })
      )
    : new Set(
        values
          .filter(({ isVisible: isPossibleValueVisible }) => isPossibleValueVisible)
          .map(({ isVisible: isPossibleValueVisible }) => isPossibleValueVisible.watchFields ?? [])
          // eslint-disable-next-line unicorn/no-array-reduce
          .reduce((previousValue, currentValue) => {
            return [...previousValue, ...currentValue];
          }, [])
      );

  const watchedValues: Partial<FormDataT> = useFormWatch([...possibleValueWatchFields], control);

  const adjustedFieldSpec = { ...fieldSpec };

  adjustedFieldSpec.values = isCategorizedPossibleValuesField(values)
    ? values.map(({ category, categoryLabel, values: possibleValues }) => {
        return {
          category,
          categoryLabel,
          values: possibleValues.filter(({ isVisible: isPossibleValueVisible }) => {
            return (
              !isPossibleValueVisible ||
              isPossibleValueVisible.callback(
                transformValue<FormDataT>(watchedValues, allFields),
                formMethods
              )
            );
          })
        };
      })
    : values.filter(({ isVisible: isPossibleValueVisible }) => {
        return (
          !isPossibleValueVisible ||
          isPossibleValueVisible.callback(
            transformValue<FormDataT>(watchedValues, allFields),
            formMethods
          )
        );
      });

  return (
    <Field
      fieldSpec={adjustedFieldSpec}
      formId={formId}
      i18n={i18n}
      className={cx(className)}
      allFields={allFields}
      formMethods={formMethods}
      onFormSubmit={onFormSubmit}
      submitOnChange={submitOnChange}
    />
  );
};

export default PossibleValuesFieldDecorator;
