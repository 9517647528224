import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';

interface Props {
  /**
   * The component to render as the root for the link
   */
  as?: React.ElementType;

  /**
   * Class name(s) to apply to the link
   */
  className?: string;

  /**
   * The URI that the link should go to
   */
  href: string;

  /**
   * @callback fired when link is clicked
   */
  onClick?(event: React.MouseEvent): void;

  /**
   * A React reference to `as` component
   */
  anchorRef?: React.MutableRefObject<HTMLElement>;

  /**
   * Aria label on link
   */
  ariaLabel?: string;

  /**
   * A string identifier that can be used by testing frameworks
   */
  testId?: string;
}

/**
 * A link that automatically protects privacy and security, & opens in a new tab
 * @author Rosalyn Rowe
 */
const ExternalLink: React.FC<React.PropsWithChildren<Props>> = ({
  as = 'a',
  children,
  className,
  href,
  onClick,
  testId,
  anchorRef,
  ariaLabel
}) => {
  const cx = useClassNameMapper();
  const Component = as;

  return (
    <Component
      className={cx(className)}
      data-testid={testId}
      href={href}
      target="_blank"
      rel="noreferrer noopener"
      onClick={event => onClick && onClick(event)}
      ref={anchorRef}
      aria-label={ariaLabel}
    >
      {children}
    </Component>
  );
};

export default ExternalLink;
