import { useCallback } from 'react';
import type { ClassNamesFnWrapper } from 'react-bootstrap/lib/esm/createClassNames';
import { getFormSpecFromLayout } from '../../helpers/form/FormBuilder/FormBuilder';
import type { FormLayout, FormSpec } from './types';

/**
 * Sets up a memoized version of getFormSpecFromLayout
 * @returns A function used in place of getFormSpecFromLayout
 */
export default function useFormSpecFromLayout<FormDataT>() {
  const getResult = useCallback(
    (layout: FormLayout, layoutCX: ClassNamesFnWrapper, defaultFormSpec: FormSpec<FormDataT>) => {
      return getFormSpecFromLayout(layout, layoutCX, defaultFormSpec);
    },
    []
  );

  return getResult;
}
